<template>
  <div class="group">
    <div class="group-item" v-for="(item,index) in groupData" :key="index">
      <div class="group-item-info">
        <img class="group-item-info-l" :src="item.cover_picture" />
        <div class="group-item-info-r" @click="goinfo(item.id)">
          <div class="group-item-info-r-title">
            {{item.name}}
          </div>
          <div class="group-item-info-r-content">
            {{item.description}}
          </div>
          <div class="group-item-info-r-time vertical-center">
            <img  class="list-time-img" src="~assets/images/user/time.png" />
            {{item.start_at ? item.start_at.slice(5,10) : ''}}~{{ item.end_at ? item.end_at.slice(5,10) : '' }}
          </div>
        </div>
      </div>
      <div class="group-item-line">

      </div>
      <view class="group-item-btns">
        <view class="group-item-btns-item align-center" @click="star(item.id)" v-if="item.is_evaluate == 0 && item.is_study == 1">小组打分</view>
        <view class="group-item-btns-item align-center" @click="evaluate(item.id)" v-if="item.is_evaluate == 1">查看评价</view>
        <view class="group-item-btns-item align-center" @click="openCode(item.id)" v-if="item.is_join == 1">加入群聊</view>
        <view class="group-item-btns-item entity align-center" @click="card(item.id,item.name)" v-if="item.is_study == 1">学习打卡</view>
      </view>
    </div>

    <van-popup v-model:show="show" round closeable>
      <div class="popup">
        <div class="popup-title">加入群聊</div>
        <div class="van-hairline--bottom"></div>
        <div class="popup-text">扫描下方二维加入群聊与大家一起开始学习吧！</div>
        <img class="popup-img" :src="wxCode"  @click="clickImg(wxCode)"/>
        <div class="popup-bottom">
          扫码加入群聊
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
export default {
  name: "group",
  data() {
    return {
      groupData:[],
      show:false,
      wxCode:''
    }
  },
  created() {
    //  if (!this.userInfo.has_chose){
    //   this.$router.replace({path: '/'})
    // }
    this.myGroups()
  },
  mounted() {

  },
  methods: {
    //我的小组列表
    myGroups(){
      let url = this.$api.myGroups;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
            this.groupData = res.data.data
        }
      })
    },
    //  获取详情
    openCode(id){
      let url = this.$api.Groups + '/' + id;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.show = true
          this.wxCode = res.data.data.wechat_code
        }
      })
    },
    // 点击图片
    clickImg(item){
      ImagePreview({
        images: [item],
      })
    },
    // 打分
    star(id){
      this.$router.push({path: '/user/group/score', query: {id: id}})
    },
    //打卡列表
    card(id,name){
      this.$router.push({path: '/user/group/list', query: {id: id,name:name}})
    },
    // 查看评价
    evaluate(id){
      this.$router.push({path: `/study/show/${id}`,query:{evaluate:true}})
    },
    // 详情
    goinfo(id){
      this.$router.push({path: `/study/show/${id}`})
    }


  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/group/index.scss";

  .list-time-img {
    width: 28/$r;
    height: 28/$r;
    margin-right:10/$r;
  }

</style>
